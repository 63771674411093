.news-single-main {
    .news {
        &-single {
            &-wrapper {
                padding: 50px 0 110px;
            }

            &-container {
                @include max-width(960px);
            }

            &-head {
                margin-bottom: 15px;
            }

            &-block {
                background-color: $white;
                @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0.18));

                .image-container {
                    @include background-defaults;
                    height: 400px;
                    img {
                        display: none;
                    }
                }
            }
        }

        &-content {
            padding: 0;
            margin-top: 16px;
            margin-bottom: 48px;
            &-container {
                padding: 40px 60px 60px;

                .news-content{
                    ul{
                        padding-left: 20px;
                        li{
                            list-style-type: disc;
                            margin-bottom: 10px !important;
                        }
                    }
                }
                .published-time {
                    @include font-s($font-sm, $bold, 1.2, $brand-success);
                    text-transform: uppercase;

                    .far {
                        margin-right: 10px;
                        font-size: 18px;
                    }
                }
            }

            &-footer {
                @include display-inline-flex(center, space-between);

                .pdf-links {
                    a {
                        @include font-s($font-base, $bold, 1.2, $brand-success);
                    }
                }

                .social-links {
                    margin-left: auto;
                    @include display-inline-flex(center, center);

                    p {
                        margin-bottom: 0;
                        @include font-s($font-base, $bold, 1.2, $black);
                        text-transform: uppercase;
                        margin-right: 10px;
                    }

                    a {
                        margin-right: 10px;
                    }
                }
            }
        }

        &-title {
            margin-bottom: 50px;
            text-align: center;
        }

        @include media-breakpoint-down(sm)
        {
            &-single {
                &-wrapper {
                    padding: 50px 0;
                }
            }
            &-content {
                &-container {
                    padding: 40px 30px 30px;
                }

                &-footer {
                    @include display-flex(column, flex-start, flex-start);
                    .pdf-links {
                        margin-bottom: 10px;
                    }

                    .social-links {
                        margin-left: 0;
                    }
                }
            }

            &-title {
                margin-bottom: 30px;
            }
        }
    }
}
