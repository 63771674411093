/*----------  Body  ----------*/

body
{
	@include font-p($font-base, $regular, 1.2, $brand-dark);
	background-color: $brand-light;
}

.brand-logo {
	width: 260px;
	@include font-s(rem-calc(32px), $bold, 1.18, $brand-success);
	letter-spacing: -2.5px;

	&:hover, &:active, &:focus
	{
		color: $brand-success;
	}
}

ul {
	@include extends('.list-unstyled');
}

h1 {
	@include font-s($font-xxl, $bold, 1.2, $black);
	@supports (-webkit-text-stroke: 2px white) {
		.text-stroke {
			-webkit-text-stroke: 2px white;
			-webkit-text-fill-color: transparent;
		}
    }

    @include media-breakpoint-down(xs)
    {
        font-size: $font-xl;
    }
}

h2 {
	@include font-s(rem-calc(32px), $bold, 1.2, $black);

	&.lg {
		font-size: rem-calc(36px);
    }

    @include media-breakpoint-down(xs)
    {
        font-size: rem-calc(24px);

        &.lg {
            font-size: rem-calc(32px);
        }
    }
}

h3 {
    @include font-s(rem-calc(24), $bold, 1.2, $black);
    text-transform: uppercase;
}

p {
    @include font-p($font-base, $regular, 1.75, $brand-secondary);
    margin-bottom: 30px;

    &.highlight {
        @include font-p(rem-calc(19px), $bold, 1.34, $brand-gray-02);
        font-style: italic;
    }

    &:last-child{
        margin-bottom: 0;
    }
}

.card-body {
    ul {
        li {
            padding-left: 15px;
            margin-bottom: 10px;
            @include position(relative);

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                @include border-radius(10px);
                background-color: $brand-secondary;
                @include absolute-center(y);
                left: 0;
            }
        }
    }
}

.circle1,.circle2{
    fill: #CADB33;
}
.circle2{
    opacity: 0.4;
    animation-name: highlight;
    animation-duration: 3s;
    animation-direction: linear;
    animation-iteration-count: infinite;
}
@keyframes highlight{
    50%{
        r : 52;
    }
}

.play-icon {
    &-element {
        use {
            @include transition;
        }
    }

    &:hover, &:active, &:focus
    {
        g.play-icon-element {
            use {
                // fill: $brand-success;
            }
        }
    }
}

a {
    color: $brand-success;

    &:hover, &:active, &:focus
    {
        color: $brand-dark
    }
}

.form {
    &-group {
        select::-ms-expand {	display: none; }
        select{
            -webkit-appearance: none;
            appearance: none;
        }

        margin-bottom: 20px;

        &-select {
            @include position(relative);

            &:after {
                content: '\f107';
                font-family: $font-lineawesome;
                font-size: 12px;
                color: $black;
                font-weight: $bold;
                @include position(absolute, 2, 50%, auto, 15px, auto);
                @include transform(translateY(-50%));
            }
        }
    }

    &-control {
        height: auto;
        padding: 12px 20px;
        border: 1px solid $brand-gray-07;
        @include font-p($font-sm, $regular, 1.1, $brand-gray-02);

        @include placeholder($brand-gray-03);

        &:hover, &:active, &:focus
        {
            border: 1px solid $brand-success;
        }
    }

}

.title {
	@include font-s($font-lg, $bold, 1.2, $black);
    margin-bottom: 30px;
    text-transform: uppercase;

	&:last-child{
		margin-bottom: 0;
	}
}

.btn-theme {
	background-color: $brand-success;
	@include font-s($font-base, $bold, 1.2, $white);
	padding: 10px 16px;
	@include border-radius(4px);
	text-transform: uppercase;
	border: 1px solid $brand-success;

	svg {
		margin-left: 8px;
		vertical-align: baseline;
		g {
			stroke: $white;
			@include transition;
		}
	}

	&:hover, &:active, &:focus
	{
		background-color: $white;
		color: $brand-success;

		svg {
			g {
				stroke: $brand-success;
			}
		}
	}

	&-xs {
		font-size: $font-xs;
		padding: 8px 13px;
	}

	&-sm {
		font-size: $font-xs;
	}

	&-md {
		padding: 10px 27px;
	}

	&-white {
		background-color: $white;
		color: $brand-success;
		border-color: $white;

		svg {
			g {
				stroke: $brand-success;
			}
		}

		&:hover, &:active, &:focus
		{
			background-color: $brand-success;
			color: $white;
			border-color: $brand-success;

			svg g {
				stroke: $white;
			}
		}
	}

	&-transparent {
		background-color: transparent;
		border-color: $brand-gray-01;
		color: $brand-dark;
		svg g {
			stroke: $brand-gray-01;
		}

		&:hover, &:active, &:focus
		{
			background-color: $brand-success !important;
			border-color: $brand-success !important;
			color: $white !important;
			svg g {
				stroke: $white;
			}
		}
    }

    &-preicon {
        svg {
            margin-right: 8px;
            margin-left: 0;
            @include transform(rotate(180deg));
        }
    }
}

.default-banner-wrapper {
    @include position(relative);
    @include background-defaults;
    padding: 50px;
    &:after {
        content: '';
        background-color: rgba($brand-success, 0.77);
        @include position(absolute, -1, 0, 0, 0, 0);
    }

    h1 {
        font-family: $sub-font-01;
        color: $white-color;
        font-weight: $regular;
        text-align: center;
        text-transform: uppercase;
    }

    @include media-breakpoint-down(sm)
    {
        padding: 40px 0;

        h1 {
            font-size: $font-xl;
        }
    }
}


.custom-check {
    input {
        width: 0;
        height: 0;
        @include opacity(0);
    }

    label {
        cursor: pointer;
        @include display-inline-flex(flex-start, flex-start);
        @include font-p($font-sm, $regular, 1.8, $brand-secondary);

        svg {
            min-width: 33px;
            margin-right: 14px;
            margin-top: 7px;
            path {
                @include opacity(0);
                @include transition;
            }
        }
    }

    input:checked + label {
        svg path {
            @include opacity(1);
        }
    }
}

.social-link {
    width: 40px;
    height: 40px;
    border: 1px solid $brand-gray-01;
    @include border-radius(4px);
    @include display-inline-flex(center, center);
    svg g {
        fill: $brand-dark;
        @include transition;
    }

    &-sm {
        width: 32px;
        height: 32px;
    }


    &:hover
    {
        background-color: $brand-success;
        svg g {
            fill: $white;
        }
    }
}

.modal-backdrop {
    background-color: $brand-success;

    &.show {
        @include opacity(0.9);
    }
}

.cookiebar {
    @include position(fixed, 999, auto, 0, 0, 0);
    &-wrapper {
        background-color: $white;
        padding: 15px 0;
        @include box-shadow(0 -10px 10px 0 rgba(0,0,0,0.1));
    }

    &-container {
        @include display-inline-flex(center, space-between);
        p {
            margin-bottom: 0;
        }
    }
}
