.news {
    &-block {
        background-color: $white;
        @include display-inline-flex(center, center);
        @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0.18));
        @include transition;
        margin-bottom: 40px;

        p {
            font-size: $font-sm;
            margin-bottom: 15px;

            &.news-title {
                margin-bottom: 20px;
                font-size: $font-lg;
            }
        }

        &:hover
        {
            @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.25));
        }

    }

    &-image {
        min-width: 200px;
        @include background-defaults;
        min-height: 251px;

        img {
            display: none;
        }
    }

    &-content {
        padding: 30px;
        width: 100%;
        @include display-flex(column, flex-start, center);
    }

    @include media-breakpoint-down(xs)
    {
        &-block {
            @include display-flex(column, center, center);
        }

        &-image{
            min-width: auto;
            width: 100%;
        }
    }

}
