.contact-main {
    .contact {
        &-wrapper {
            padding: 75px 0;
        }

        &-form {
            &-wrapper {
                background-color: $white;
                padding: 40px;
                @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0.18));

                .form {
                    &-head {
                        margin-bottom: 34px;
                    }


                    &-foot {
                        text-align: center;
                    }
                }
            }
        }

        &-content {
            &-wrapper {
                margin-top: 43px;

                .highlight {
                    font-size: $font-lg;
                }
            }

            &-list {
                margin-bottom: 50px;
                p {
                    margin-bottom: 15px;
                }

                ul {
                    li {
                        margin-bottom: 20px;
                        @include display-inline-flex(flex-start, flex-start);
                        svg {
                            min-width: 20px;
                        }
                        p {
                            line-height: 1.2;
                            margin-left: 15px;
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .title {
                margin-bottom: 20px;
            }

        }
        &-details {
            p {
                margin-bottom: 4px;
                @include font-p($font-base, $regular, 1.2, $brand-gray-02);

                &.name {
                    font-size: $font-md;
                    font-family: $font-secondary;
                    text-transform: uppercase;
                    font-weight: $bold;
                }
            }

            ul {
                margin-top: 36px;

                li {
                    margin-bottom: 11px;
                    a {
                        @include display-inline-flex(center, flex-start);
                        @include font-p($font-base, $regular, 1.2, $brand-success);

                        svg {
                            min-width: 18px;
                            margin-right: 11px;
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            &-details {
                margin-bottom: 30px;

                ul {
                    margin-top: 15px;
                }
            }
        }

        @include media-breakpoint-down(xs)
        {
            &-content {
                &-wrapper {
                    .highlight {
                        font-size: $font-md;
                    }
                }
            }

            &-form {
                &-wrapper {
                    padding: 20px;
                }
            }
        }
    }
}
