.news-listing-main {
    .news-listing {
        &-wrapper {
            padding: 100px 0;
        }

        &-container {
            margin-bottom: 80px;
            @include max-width(960px);
        }

        &-footer {
            text-align: center;
        }
    }
    .news-image {
        max-width: 350px;
        width: 100%;
    }

    @include media-breakpoint-down(xs)
    {
        .news-listing {
            &-wrapper {
                padding: 50px 0;
            }
        }

        .news-image {
            max-width: 100%;
        }
    }
}
