/*----------  Font Variables  ----------*/
$font-primary				: 'Roboto', sans-serif;
$font-secondary				: 'Roboto Condensed', sans-serif;
$sub-font-01				: 'Bebas Neue', cursive;
// $sub-font-02				: sans-serif;

/*----------  Icon Fonts  ----------*/
$font-fontawesome			: 'Font Awesome 5 Free';
$font-lineawesome			: 'Line Awesome Free';

/*----------  Color Variables  ----------*/

/*----------  Bootstrap's Defaults  ----------*/
// 7b7b7b

$brand-primary				: #007bff;
$brand-secondary			: #838383;
$brand-success				: #008d7d;
$brand-info					: #17a2b8;
$brand-warning				: #ffc107;
$brand-danger				: #dc3545;
$brand-light				: #fcfcfc;
$brand-dark					: #7b7b7b;

/*-----  Default Variables  -----*/

$white-color				: #fff !default;
$black-color				: #000 !default;

/*-----  Unsorted Variables  -----*/

// $color-01					: #;
// $color-02					: #;

/*-----  Brands Variables  -----*/

/* Brand Primary */
// $brand-primary-01			: #;
// $brand-primary-02			: #;

/* Brand Info */
// $brand-info-01				: #;
// $brand-info-02				: #;

/* Brand Warning */
// $brand-warning-01			: #;
// $brand-warning-02			: #;

/* Brand Success */
$brand-success-01			: #cadb33;
$brand-success-02			: #edfaf8;
$brand-success-03			: #d5f2ee;

/* Brand Danger */
// $brand-danger-01				: #;
// $brand-danger-02				: #;

/* Brand Gray */
$brand-gray-01				: #ebebeb;
$brand-gray-02				: #595959;
$brand-gray-03				: #b1b1b1;
$brand-gray-04				: #e5e5e5;
$brand-gray-05				: #adadad;
$brand-gray-06				: #f9f9f9;
$brand-gray-07				: #d4d4d4;
$brand-gray-08				: #090909;


/*----------  Font Weight Variables  ----------*/

$hairline 			: 100;
$light	 			: 300;
$regular 			: 400;
$medium				: 500;
$semibold 			: 600;
$bold	 			: 700;
$extrabold	 		: 900;

/*----------  Font Size Variables  ----------*/

$font-base					: rem-calc(16);					// 16px
$font-xs					: $font-base - rem-calc(4);		// 12px
$font-sm					: $font-base - rem-calc(2);		// 14px
$font-md					: $font-base + rem-calc(2);		// 18px
$font-lg					: $font-base + rem-calc(4);		// 20px
$font-xl					: $font-base + rem-calc(24);	// 40px
$font-xxl					: $font-base + rem-calc(44);	// 60px

/*----------  Grid  ----------*/

$grid-space-default			: 40;
$col-name					: 'cs-col';

/*----------  Containers  ----------*/

$container-default-gutter: 40;
$container-default-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);
