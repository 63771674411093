.about-main {
    .about {
        &-wrapper {
            padding: 75px 0 100px
        }

        &-container {

        }

        &-tabs {
            border-bottom: 0px;
            margin: 0 -20px;
            margin-bottom: 104px;
        }

        &-tab {
            @include flex(1);
            margin: 0 20px;

            &-link {
                display: block;
                padding: 40px;
                @include display-flex(column, center, center);
                @include position(relative);
                background-color: $white;
                @include box-shadow(0 10px 40px 0 rgba($brand-gray-03, 0.18));

                svg g {
                    stroke: #5D5D5D;
                    @include transition;
                }

                h3{
                    @include transition;
                }

                &:after {
                    content: '';
                    height: 3px;
                    width: 0;
                    background-color: $brand-success;
                    @include position(absolute, 0, auto, 50%, 0, 0);
                    @include transform(translateX(-50%));
                    @include transition;
                }

                h3 {
                    margin-top: 27px;
                }

                &.active {
                    @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.18));

                    h3 {
                        color: $brand-success;
                    }

                    svg g {
                        stroke: $brand-success;
                    }

                    &:after {
                        width: 100%;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-tabs {
                margin-bottom: 40px;
            }
            &-tab {
                &-link {
                    height: 100%;
                    text-align: center;
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            &-tab {
                margin: 0;
                &-link {
                    padding: 20px;
                    h3 {
                        margin-top: 10px;
                        font-size: $font-lg;
                    }

                    &.active {
                        @include box-shadow(none);
                    }
                }
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-tabs {
                margin-bottom: 30px;
            }
        }

        @include media-breakpoint-down(xs)
        {
            &-wrapper {
                padding: 75px 0 0 0;
            }
            &-tabs {
                display: none;
            }

            &-tab {
                &-content>.about-tab-pane {
                    display: block;
                    @include opacity(1);
                    margin-bottom: 60px;
                }
            }
        }
    }

    .equipe{
        &-green{
            &-wrapper{
                img{
                    border-radius: 15px;
                    @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.18));
                    margin-bottom: 65px;
                }

                h3{
                    margin-bottom: 20px;
                    text-align: center;
                    color: $brand-gray-08;
                }
            }
        }

        &-team{
            &-wrapper{
                img{
                    border-radius: 15px;
                    @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.18));
                    margin-bottom: 129px;
                }

                h3{
                    text-align: center;
                    margin-bottom: 20px;
                    color: $brand-gray-08;
                }
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-green-wrapper, &-team-wrapper {
                img {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .elke{
        &-about{
            &-wrapper{

                h3{
                    color: $brand-gray-08;
                    margin-bottom: 20px;
                }
            }
            &-media{
                @include display-flex(row,normal,flex-start,nowrap,false);
                margin-bottom: 80px;

                img{
                    border-radius: 20px;
                    margin-left: 60px;
                    @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.19));
                }

                &-content{
                    margin: 70px 0px 0px 41px;

                    h1{
                        text-transform: uppercase;
                        margin-bottom: 17px;
                    }
                }
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-about {
                &-media {
                    margin-bottom: 50px;
                    img {
                        margin-left: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            &-about {
                &-media {
                    @include display-flex(column, center, center);

                    &-content {
                        margin: 0;
                        margin-top: 20px;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}
