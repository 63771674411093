/*----------  Header  ----------*/

header
{
	@include position(relative, 999);
	.header {
		&-wrapper {
			background-color: $white;
		}
		&-container {
			@include display-inline-flex(center, space-between);

			.brand-wrapper {
				.brand-logo {
					width: 260px;
					@include font-s(rem-calc(32px), $bold, 1.18, $brand-success);
					letter-spacing: -2.5px;
				}
			}

			.nav {
				&-wrapper{
					@include display-inline-flex(center, flex-end);

					.language {
						&-container {
							@include position(relative);
							a {
								@include font-p($font-sm, $bold, 1.15, $brand-dark);
								display: block;
							}

							.current {
								@include position(relative);
								padding: 8px 26px 8px 11px;
								border: 1px solid $brand-gray-01;
								@include border-radius(4px);
								&:after {
									content: '\f107';
									font-family: $font-fontawesome;
									font-weight: $bold;
									font-size: 16px;
									color: $brand-gray-01;
									@include position(absolute, 2, $right: 11px, $top: 50%);
									@include transform(translateY(-50%));

								}
							}

							&:hover, &:active, &:focus
							{
								.language-list {
									@include opacity(1);
									@include pointer-events(all);
								}
							}
						}
						&-list {
							// @include opacity(0);
							@include position(absolute, 3, calc(100% - 4px), 0, 0);
							background-color: $brand-light;
							border: 1px solid $brand-gray-01;
							border-width: 0 1px 1px 1px;
							@include opacity(0);
							@include pointer-events(none);
							@include border-radius(0 0 4px 4px);
							@include transition;


							a {
								padding: 8px 11px;
								&:hover, &:active, &:focus
								{
									background-color: $brand-gray-01;
								}
							}

						}
					}

					.nav-toggler-wrapper {
						display: none;
					}
				}

				&-container {
					margin-right: 20px;
				}

				&-list {
					@include display-inline-flex(center, flex-start);

				}
				&-link {
					padding: 32px 16px;
					@include position(relative);
					margin-right: 24px;
					@include font-s($font-sm, $bold, 1.15, $black-color);

					&:before {
						content: '';
						background-color: $brand-success;
						height: 2px;
						@include position(absolute, 2, auto, 0, 0, 0);
						@include transform(translateY(2px));
						@include opacity(0);
						@include transition;
					}

					&:hover, &:active, &:focus, &.active
					{
						color: $brand-success;

						&:before {
							@include opacity(1);
							@include transform(translateY(0));
						}
					}
				}

            }
        }

        @include media-breakpoint-down(md)
        {
            &-container {
                .brand-wrapper {
                    margin-top: 30px;
                }
                @include display-flex(column, center, center);
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-wrapper {
                padding: 30px 0;
            }
            &-container {
                .brand-wrapper {
                    margin-top: 0;
                    @include position(relative, 1000);
                }
                @include display-inline-flex(center, space-between);

                .nav {

                    &-wrapper {
                        .language-container {
                            @include position(relative, 1000);
                        }
                        .nav-toggler {
                            &-wrapper {
                                display: block;
                                margin-left: 15px;
                                @include position(relative, 1000);
                            }

                            font-size: 28px;
                            @include transition;
                            &.open {
                                @include transform(rotate(180deg));
                                .las:before {
                                    content: '\f00d';
                                }
                            }
                        }
                    }
                    &-container {
                        background-color: rgba($white, 0.95);
                        margin: 0;
                        @include position(fixed, 999, 0, 0, 0, 0);
                        @include display-flex(column, center, center);
                        @include opacity(0);
                        @include pointer-events(none);
                        @include transition;

                        &.show {
                            @include opacity(1);
                            @include pointer-events(all);
                        }
                    }

                    &-list {
                        @include display-flex(column, center, center);
                    }

                    &-item {
                        margin-bottom: 30px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &-link {
                        margin-right: 0;
                        padding: 0;
                        padding-bottom: 5px;
                        font-size: $font-md;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs)
        {
            &-container {
                .brand-wrapper {
                    .brand-logo {
                        font-size: 28px;
                        width: auto;
                        letter-spacing: -2px;
                    }
                }
            }
        }
	}
}
