.engagement-main {
    .overview {
        &-wrapper {
            padding: 100px 0;
        }
        &-container {
            @include display-inline-flex(center, space-between);
        }

        &-media-container {
            @include order(2);
            @include position(relative);
            margin-left: 60px;

            img {
                @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.14));
            }

            .play-icon {
                @include absolute-center;
            }
        }

        &-content {
            margin-right: 10px;
            max-width: 560px;

            h2 {
                text-transform: uppercase;
                margin-bottom: 40px;
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-content {
                max-width: 50%;
            }
        }

        @include media-breakpoint-down(md)
        {
            &-content {
                max-width: 100%;
                margin-bottom: 20px;
            }

            &-container {
                @include display-flex(column, center, center);
            }

            &-media-container {
                margin-left: 0;
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-wrapper {
                padding: 50px 0;
            }

            &-content {
                h2 {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .engagements {
        &-container {
            @include max-width(840px);
        }

        &-accordian {
            @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0.18));
            .card {
                @include border-radius(0);
                border: 0;
                &-header {
                    padding: 0;
                    margin-bottom: 0;
                    border-bottom: 1px solid rgba($brand-gray-04, 0.5);
                    background-color: $white;
                    a {
                        display: block;
                        padding: 20px 30px;
                        @include font-s(rem-calc(24px), $bold, 1.2, $brand-success);
                        @include display-inline-flex(center, space-between);

                        .icon {
                            width: 18px;
                            height: 18px;
                            @include position(relative);
                            &:before, &:after {
                                content: '';
                                @include position(absolute);
                                top: 50%;
                                @include transform(translateY(-50%));
                                background-color: $brand-gray-05;
                                height: 3px;
                                width: 18px;
                                @include border-radius(999px);
                                @include transition;
                            }

                            &:before {
                                @include transform(translateY(-50%) rotate(90deg));
                                @include opacity(0);
                            }
                        }

                        &.collapsed {
                            color: $black;
                            @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0));

                            .icon {
                                &:before {
                                    @include opacity(1);
                                }
                            }
                        }
                    }
                }

                &-body {
                    background-color: $brand-gray-06;
                    @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0.18) inset);
                    padding: 40px;

                    p {
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                &:last-child {
                    .card-header {
                        border: 0;
                    }
                }
            }
        }
    }

    .community {
        &-wrapper {
            padding: 130px 0 166px;
            @include position(relative);

            &:before {
                content: '';
                background-color: $brand-success;
                @include position(absolute, -1, auto, 0, 0, 0);
                height: 583px;
            }
        }

        &-block {
            background-color: $white;
            padding: 60px 70px 80px;
            @include box-shadow(0 20px 50px 0 rgba($brand-gray-03, 0.18));

            h2 {
                text-align: center;
                margin-bottom: 80px;
            }

            &-content {
                &-wrapper {
                    @include display-inline-flex(center, space-between);
                }

                max-width: 50%;
                margin-right: 30px;

                p {
                    margin-bottom: 30px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &-media {
                @include order(2);
                margin-left: 30px;
                @include display-flex(column, center, center);

                img {
                    margin-bottom: 50px;
                }
            }
        }
        
        &-video {
            @include position;
            .play-icon {
                @include absolute-center;
            }
        }

        @include media-breakpoint-down(md)
        {
            &-wrapper {
                padding: 50px 0;
            }

            &-block {
                h2 {
                    margin-bottom: 30px;
                }
                &-content {
                    max-width: 100%;
                    margin-right: 0;
                    margin-bottom: 10px;
                    &-wrapper {
                        @include display-flex(column, center, center);
                    }
                }

                &-media {
                    margin-left: 0;
                }
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-block {
                padding: 20px;
                h2 {
                    line-break: anywhere;
                }
            }
        }
    }
}
