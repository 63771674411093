/*----------  Index View  ----------*/
.home-main {
    .banner {
        &-wrapper {
            min-height: 800px;
            @include display-inline-flex(center, center);
            @include background-defaults;
            @include position;
        }

        &-video {
            &-container {
                @include position(absolute, -2, 0, 0, 0, 0);
                overflow: hidden;
                @include display-inline-flex(center, center);

                // &:before {
                //     content: '';
                //     background-color: rgba($brand-success, 0.77);
                //     @include position(absolute, 0, 0, 0, 0, 0);
                // }
            }
        }

        &-container {
            text-align: center;
            h1 {
                font-size: rem-calc(180px);
                color: $white;
                font-family: $sub-font-01;
                font-weight: $regular;
            }

            .btn-theme-transparent {
                border-color: $white;
                color: $white;
                svg g {
                    stroke: $white;
                }
            }
        }

        &-links {
            @include display-inline-flex(center, center);

            li {
                margin-right: 30px;

                &:last-child{
                    margin: 0;
                }
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-wrapper {
                min-height: 500px;
            }
            &-container {
                h1 {
                    font-size: 100px;
                    line-height: 1;
                }
            }
        }

        @include media-breakpoint-down(xs)
        {
            &-container {
                h1 {
                    font-size: 60px;
                }
            }

            &-links {
                @include flex-direction(column);
                margin-top: 15px;

                li {
                    margin-right: 0;
                    margin-bottom: 15px;
                }
            }
        }
    }

    .news {
        &-wrapper {
            padding: 150px 0 190px;
        }

        &-container {
            &-head {
                margin-bottom: 40px;
                @include display-inline-flex(center, space-between);

                a {
                    @include font-s($font-lg, $bold, 1.2, $brand-success);
                    @include position(relative);
                    padding-right: 20px;

                    svg {
                        @include position(absolute, $top: 50%, $right: 0);
                        @include transform(translateY(-50%));
                        @include transition;
                    }

                    &:hover, &:active, &:focus
                    {
                        svg {
                            @include transform(translate(5px, -50%));
                        }
                    }
                }
            }
        }

        &-blocks-wrapper {
            @include display-inline-flex(center, center);
            @include flex-wrap(wrap);
            margin: 0 -20px;

            .news-block {
                width: 560px;
                margin-left: 20px;
                margin-right: 20px;

                @include media-breakpoint-down(lg)
                {
                    max-width: 45%;
                }
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-wrapper {
                padding-bottom: 50px;
            }
        }

        @include media-breakpoint-down(md)
        {
            &-blocks-wrapper {
                .news-block {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-wrapper {
                padding: 50px 0;
            }

            &-container {
                &-head {
                    margin-bottom: 20px;
                    @include display-flex(column, flex-start, flex-start);

                    h2 {
                        margin-bottom: 5px;
                    }

                    a {
                        font-size: $font-base;
                    }
                }
            }
        }
    }

    .overview {
        &-wrapper {
            background-color: $white-color;
            @include position(relative);

            &:before {
                content: '';
                background-color: $brand-success-01;
                @include position(absolute, -2, 0, 0, auto, 0);
                width: 42.2%;
            }

            .text-overlay {
                @include position(absolute, -1, -36px, 0, auto, 0);
                height: 100%;
                @include pointer-events(none);
            }
        }

        &-media-container {
            @include position(absolute, 2, -81px, 50%, auto, -37px);
            @include transform(translateX(-100%));

            img {
                // max-width: 660px;
            }
        }

        &-content-container {
            max-width: 540px;
            margin-left: auto;
            padding: 95px 0 180px;

            h1 {
                margin-bottom: 17px;
            }

            p {
                margin-bottom: 30px;
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-wrapper {
                background-color: transparent;
                &:before {
                    display: none;
                }

                .text-overlay {
                    display: none;
                }
            }

            &-media-container {
                @include reset-position;
                text-align: center;
            }

            &-content-container {
                max-width: 100%;
                padding: 50px 0;
                text-align: center;

                h1 {
                    margin-bottom: 10px;
                }

                p {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .city{
        &-views-wrapper {
            background-color: $brand-success-02;
        }

        &-overview {
            &-wrapper {
                padding: 221px 0 90px;
                @include position(relative);

                &:before {
                    content: '';
                    background-color: $brand-success;
                    @include position(absolute, -1, 0, auto, 0, 0);
                    width: 382px;
                }

            }

            &-media {
                @include position(absolute, 2, auto, calc(50% + 180px), auto, 80px);

                &-inner {
                    @include position(relative);

                    img {
                        @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.14));
                    }

                    .play-icon {
                        @include absolute-center;
                    }

                    &:after {
                        content: '';
                        background-color: $white;
                        @include position(absolute, -2, 0, 0, 382px, -80px);
                        @include box-shadow(0 20px 50px 0 rgba($brand-success, 0.25));
                    }
                }
            }

            &-content {
                max-width: 660px;

                h2, p {
                    margin-bottom: 30px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                .title {
                    margin-top: 50px;
                }

                h2, .title {
                    text-transform: uppercase;
                }
            }
        }

        &-beliefs {
            &-wrapper {
                @include position(relative);
                padding: 132px 0 105px;

                &:before {
                    content: '';
                    background-color: $brand-success-01;
                    width: 19.8%;
                    @include position(absolute, -1, 132px, 0, auto, 0);
                }
            }

            &-container {
                @include display-inline-flex(flex-start, space-between);
                @include flex-wrap(wrap);
            }

            &-block {
                @include position(relative);
                margin-right: 40px;
                &-content {
                    background-color: $white;
                    padding: 150px 100px 75px 0;
                    max-width: 760px;
                }

                &:before {
                    content: '';
                    background-color: $white;
                    width: 88px;
                    @include position(absolute, 0, 0, -88px, auto, 0);
                }
            }

            &-testimonial {
                max-width: 328px;

                &-inner {
                    @include position(relative);
                    padding-top: 42px;

                    .quote {
                        @include position(absolute, -1, 0, auto, -30px, auto);

                        @media (max-width: 1300px) {
                            right: 0;
                        }
                    }
                }

                p {
                    @include font-p(rem-calc(24px), $bold, 1.35, $brand-gray-02);
                    margin-bottom: 35px;
                    font-style: italic;
                }
            }

            &-image {
                @include position(absolute, 2, -132px, 0, auto, auto);
            }
        }

        @include media-breakpoint-down(lg)
        {
            &-overview {
                padding: 100px 0 50px;
                &-wrapper {
                    padding: 50px 0;
                    &:before {
                        display: none;
                    }
                }
                &-media {
                    @include reset-position;
                    text-align: center;
                    margin-bottom: 20px;
                    &-inner {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &-beliefs {
                &-wrapper {
                    &:before {
                        display: none;
                    }
                }

                &-block {
                    &-content {
                        max-width: 100%;
                    }
                }

                &-testimonial {
                    margin-top: 30px;
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            &-beliefs {
                &-block {
                    margin-right: 0;

                    &-content {
                        padding: 150px 75px 75px;
                        padding-left: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm)
        {
            &-overview {
                &-wrapper {
                    padding-top: 0;
                }
                &-content {
                    .title {
                        margin-top: 0;
                    }
                }
            }
        }

        @include media-breakpoint-down(xs)
        {
            &-beliefs {
                &-wrapper {
                    padding: 0px 0 50px;
                }
                &-image {
                    @include reset-position;
                    width: 100%;
                }

                &-block {
                    &:before {
                        display: none;
                    }

                    &-content {
                        padding: 20px;
                    }
                }

                &-testimonial {
                    max-width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
}
