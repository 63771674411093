/*----------  Footer  ----------*/

footer
{
	@include position;
	background-color: $white;

	.footer {
		&-wrapper {
			padding: 38px 0 72px;
		}

		&-container {
			@include display-inline-flex(flex-start, space-between);

			.social {
				&-list {
					width: 360px;
					@include display-inline-flex(center, flex-end);

					li {
						margin-right: 15px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}

		&-nav {
			&-list {
				@include display-inline-flex(center, flex-start);
				@include flex-wrap(wrap);
				width: 360px;

                .breaker{
                    flex-basis: 100%;
                }

				.nav-link {
					padding: 0;
					margin-bottom: 27px;
					margin-top: 10px;
					@include position(relative);
					margin-right: 43px;
					text-transform: uppercase;
					@include font-s($font-sm, $bold, 1.15, $black-color);

					&:hover, &:active, &:focus, &.active
					{
						color: $brand-success;
					}
                }
                
                @include media-breakpoint-down(lg){
                    .breaker{
                        flex-basis: 0%;
                    }
                }
			}
		}

		&-meta {
			&-container {
				padding: 30px 0;
				border-top: 1px solid $brand-gray-01;
				@include display-inline-flex(center, space-between);

				p {
					@include font-p($font-xs, $bold, 1.15, $black);
					text-transform: uppercase;
					margin-bottom: 0px;

					a {
						color: $brand-success;

						&:hover, &:active, &:focus
						{
							color: $black;
						}
					}
				}
			}
        }

        @include media-breakpoint-down(lg)
        {
            &-wrapper {
                .brand-logo {
                    width: auto;
                    white-space: nowrap;
                }
            }
        }

        @include media-breakpoint-down(md)
        {
            &-wrapper {
                padding: 35px 0;
                .brand-logo {
                    margin-bottom: 20px;
                }
            }
            &-container {
                @include display-flex(column, center, center);

                .social {
                    &-list {
                        width: 100%;
                        max-width: 100%;
                        margin: 0 auto;
                        @include justify-content(center);
                    }
                }
            }

            &-nav {
                &-list {
                    width: 100%;
                    max-width: 100%;
                    @include align-items(center);
                    @include justify-content(center);
                    margin-bottom: 20px;
                    .nav-link {
                        margin: 0 20px;
                    }
                }
            }

            &-meta {
                &-container {
                    @include display-flex(column, center, center);

                    p {
                        margin-bottom: 15px;
                        text-align: center;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

        }

        @include media-breakpoint-down(xs)
        {

            &-nav {
                &-list {
                    .nav-link {
                        margin: 0 20px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
	}
}
